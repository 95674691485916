import { bool, shape } from 'prop-types';
import React from 'react';
var SdkMagaluAds = function SdkMagaluAds(_ref) {
  var toggleAds = _ref["static"].toggleAds;
  var setupEvents = "(function(d,c){var s=d.createElement('script');s.type='text/javascript';s.defer=!0;s.fetchPriority=\"low\";s.src='https://static-magalu-ads.magazineluiza.com.br/sponsored-products-sdk/2.16.1/magalu-ads-events.js';s.onload=c;d.head.appendChild(s);})(document);";
  return React.createElement("script", {
    dangerouslySetInnerHTML: {
      __html: toggleAds ? setupEvents : ''
    }
  });
};
SdkMagaluAds.ssr = true;
SdkMagaluAds.defaultProps = {
  "static": {
    toggleAds: true
  }
};
process.env.NODE_ENV !== "production" ? SdkMagaluAds.propTypes = {
  "static": shape({
    toggleAds: bool
  })
} : void 0;
export default SdkMagaluAds;