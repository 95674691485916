var parseHeaderData = function parseHeaderData(_ref) {
  var _menuData$data, _menuData$data$accoun, _data$channel, _data$channel$data, _data$channel$data$fi, _menuData$data2;
  var staticProps = _ref.staticProps,
    structure = _ref.structure,
    data = _ref.data;
  var _staticProps$menuData = staticProps.menuData,
    menuData = _staticProps$menuData === void 0 ? {
      data: data
    } : _staticProps$menuData,
    headerData = staticProps.headerData;
  var _ref2 = structure.cookies || {},
    _ref2$accountData = _ref2.accountData,
    accountData = _ref2$accountData === void 0 ? {} : _ref2$accountData;
  if ((menuData == null ? void 0 : (_menuData$data = menuData.data) == null ? void 0 : (_menuData$data$accoun = _menuData$data.account) == null ? void 0 : _menuData$data$accoun.name) !== undefined && (accountData == null ? void 0 : accountData.name)) {
    menuData.data.account.name = accountData.name;
  }
  var showcases = (data == null ? void 0 : (_data$channel = data.channel) == null ? void 0 : (_data$channel$data = _data$channel.data) == null ? void 0 : (_data$channel$data$fi = _data$channel$data.find(function (item) {
    return item.name === 'showcases';
  })) == null ? void 0 : _data$channel$data$fi.value) || [];
  if ((menuData == null ? void 0 : menuData.data) && showcases && showcases.length) {
    menuData.data.showcaseLinks = showcases;
  }
  if ((accountData == null ? void 0 : accountData.name) && (menuData == null ? void 0 : (_menuData$data2 = menuData.data) == null ? void 0 : _menuData$data2.header)) {
    menuData.data.header.label = menuData.data.header.loggedLabel;
    menuData.data.header.url = menuData.data.header.loggedUrl;
  }
  return {
    headerData: headerData,
    menuData: menuData
  };
};
export default parseHeaderData;